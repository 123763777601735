
import React, { useContext, useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './modal.css';
const ModalContext = React.createContext();
export function ModalProvider({ children }) {
  const modalRef = useRef();
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(modalRef.current);
  }, [])
  return (
    <>
      <ModalContext.Provider value={value}>
        {children}
      </ModalContext.Provider>
      <div ref={modalRef} />
    </>
  );
}
export default function Modal({ onClose, children }) {
  const modalNode = useContext(ModalContext);
  if (!modalNode) return null;
  return ReactDOM.createPortal(
    <div id="modal" style={{zIndex: "1000"}}>
      <div id="modal-background" onClick={onClose} />
      {/* <div className='nice'> */}
      <div id="modal-content" className='sign'>
        {children}

      </div>
      {/* </div> */}
    </div>,
    modalNode
  );
}